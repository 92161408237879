<template>
	<el-menu 
		router 
		class="el-menu-vertical-demo" 
		:default-active="$route.path" 
		:collapse="isCollapse" 
		:unique-opened="true"
		background-color="#343A40" 
		text-color="#FFFFFF" 
		active-text-color="#F95151" 
		@open="handleOpen" 
		@close="handleClose"> 
		<el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
			<template slot="title">
				<!-- <i :class="`el-icon-${item.icon}`"></i> -->
				<!-- 一级菜单 -->
				<span slot="title">{{ item.label }}</span>
			</template>

			<!-- 一级菜单 > 子菜单 -->
			<el-menu-item-group v-for="subItem in item.children" :key="subItem.name">
				<el-menu-item :index="subItem.path" @click="clickMenu(subItem)">
					<i class="el-icon-arrow-right"></i>
					{{ subItem.label }}
				</el-menu-item>
			</el-menu-item-group>
		</el-submenu>

		<!-- 如果一级菜单没有子菜单 -->
		<el-menu-item v-for="item in noChildren" :key="item.name" :index="item.path" 
			@click="clickMenu(item)">
			<!-- <i :class="`el-icon-${item.icon}`"></i> -->
			<span slot="title">{{ item.label }}</span>
		</el-menu-item>
	</el-menu>
</template>

<script>
import eBus     from '@/utils/eventBus.js';
import menuData from '@/utils/menu.config.js';

export default {
	data(){
		return {
			activePath: '',
			isCollapse: false
		}
	},
	methods: {
		handleOpen(key, keyPath)
		{
			this.activePath = key;
		},

		handleClose(key, keyPath)
		{
			// console.log(key, keyPath);
		},

		// 点击菜单跳转
		clickMenu(item)
		{
			// 调用父组件方法
			eBus.$emit('triggerMenuClose');

			// 当前页面的路由与跳转的路由不一致时，才允许跳转
			if
			(
				this.$route.path !== item.path
			)
			{
				// 跳转
				this.$router.push(item.path);
			}
		}
	},

	computed: {
		// 没有子菜单
		noChildren()
		{
			return menuData.filter(item => !item.children);
		},

		// 有子菜单
		hasChildren()
		{
			return menuData.filter(item => item.children);
		}
	}
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse){
	width:100%;
	min-height:8rem;
}
.el-menu{
	height:calc(100vh - 100px);
	border-right:none;
	h3{
		font-size:.32rem;font-weight:400;color:#FFF;text-align:center;line-height:.96rem;
	}
}
/deep/ .el-menu-item{padding-left:30px !important;}
/deep/ .el-menu-item,
/deep/ .el-submenu__title{
	font-size:.36rem;height:1.4rem;line-height:1.4rem;
}
/deep/ .el-submenu__title{
	padding:0;padding-left:.6rem !important;
	display:flex;justify-content:space-between;align-items:center;
	i.el-submenu__icon-arrow{font-size:.4rem;}
}
/deep/ .el-menu-item-group__title{padding:0;}
/deep/ .el-menu-item-group .el-menu-item{
	background-color:#494E53 !important;
	font-size:.32rem;color:#FFF;
	padding-left:1rem !important;
	display:flex;justify-content:flex-start;align-items:center;
	i{color:#FFF;}
	&:hover{
		color:#F95151 !important;
		i{color:#F95151 !important;}
	}
}
/deep/ .el-menu-item.is-active{
	color:#F95151;
	i{color:#F95151;}
}

@media(min-width:768px)
{
	.el-menu-vertical-demo:not(.el-menu--collapse){
		width:300px;
		min-height:400px;
	}
	.el-menu{
		h3{
			font-size:16px;
			line-height:48px;
		}
	}
	/deep/ .el-menu-item,
	/deep/ .el-submenu__title{
		font-size:18px;
		height:70px;line-height:70px;
	}
	/deep/ .el-submenu__title{
		padding-left:30px !important;
		i.el-submenu__icon-arrow{font-size:20px;}
	}
	/deep/ .el-menu-item-group .el-menu-item{
		font-size:16px;
		padding-left:50px !important;
	}
}
</style>
