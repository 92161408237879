<template>
    <div class="platform_container">
        <main-header>
			<template slot="rightcontent">
				<span class="pfHeadTit" 
                    v-for="item in topMenu" :key="item.label" 
                    @click="clickMenu(item)">
                    <!-- {{ item.children[0].label }} -->
                    {{ item.label }}
                </span>
			</template>

            <template slot="rightMenuTrigger">
                <i class="asideMenuTrigger el-icon-menu" @click="openMiniMenu"></i>
            </template>
		</main-header>

        <el-container>
            <!-- lg aside menu -->
            <el-aside width="300px" class="elAsideTopSty">
                <menu-aside></menu-aside>
            </el-aside>

            <!-- xs drawer menu -->
            <el-drawer 
                title="" 
                class="elDrawerSty" 
                :visible.sync="drawer" 
                :direction="direction" 
                :show-close="false" 
                :before-close="handleClose">
                <menu-aside></menu-aside>
            </el-drawer>

            <el-main>
                <!-- 子路由出口 -->
                <!-- 路由匹配到的组件将渲染在这里 -->
                <router-view></router-view>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import eBus       from '@/utils/eventBus.js';
import menuData   from '@/utils/menu.config.js';
import MainHeader from '@/components/MainHeader.vue';
import MenuAside  from '@/components/MenuAside.vue';

export default {
    name: 'PlatformCom',
    head: {
        title: "解忧树下",
        meta :[
            {
				name   : 'keywords',
				content: '解忧,树下,树洞,解忧树下'
			},
            {
				name   : 'description',
				content: '记录每天发生的小确幸'
			}
        ]
    },
    components: {
        MainHeader,
        MenuAside
    },
    
    data()
    {
        return {
            drawer   : false,
            direction: 'ttb',
        }
    },
    
    created()
    {
        // 监听 MenuAside.vue xs点击子菜单事件
        eBus.$on('triggerMenuClose', (obj) => {
            this.closeMiniMenu();
		});
    },
    
    methods: {
        // 点击菜单跳转
		clickMenu(item)
		{
			// 当前页面的路由与跳转的路由不一致时，才允许跳转
            if(item.children)
            {
                if(this.$route.path !== item.children[0].path)
                {
                    this.$router.push(item.children[0].path);
                }
            }
            else
            {
                if(this.$route.path !== item.path)
                {
                    this.$router.push(item.path);
                }
            }
		},

        // xs 右上角点击 》打开菜单
        openMiniMenu()
        {
            this.drawer = true;
        },

        // xs 关闭菜单显示
        closeMiniMenu()
        {
            this.drawer = false;
        },

        // xs 点击打开的菜单子项 》下方的空白处 》关闭菜单显示
        handleClose(done)
        {
            this.drawer = false;
            done();
        }
    },
    
    computed: {
		// 顶部菜单
		topMenu()
		{
			let res = menuData.filter(item => item.topShow);
            return res;
		}
	}
}
</script>

<style lang="less" scoped>
.elAsideTopSty{
    border-radius:10px 10px 0 0;border-top:3px solid #f39c12;
    display:none;
}
.platform_container{
    margin:0;padding:0;
    text-align:justify;
    /deep/ .pfHeadTit{
        display:none;
        font-size:.32rem;color:#222;cursor:pointer;
        margin-left:.6rem;padding:.12rem .3rem;border-radius:1rem;
    }
    /deep/ .asideMenuTrigger{
        display:block;
        padding:.2rem;
        font-size:.4rem;color:#f39c12;
    }
    /deep/ .pfHeadTit:hover{
        // color:#0b9ef3;
        background:rgb(52, 58, 64);
        box-shadow:1px 1px 3px #222;
        color:#FFF;
    }
}
.el-container{
    width:7rem;
    margin:0 auto;
    background:#F1F1F1;
    border-radius:.2rem;
    padding-left:.2rem;padding-right:.2rem;margin-bottom:.25rem;
}
.el-aside{overflow-x:hidden;overflow-y:hidden;}

/deep/ .htmlSty{
    font-size:.32rem;color:#222;font-weight:400;
    line-height:.6rem;
    margin-top:0;
}

/* el-drawer */
/deep/ .el-drawer{height:auto !important;}
/deep/ .el-drawer__header{margin-bottom:0;padding:.4rem;display:none;}
.el-main{padding:0 0 .4rem 0;}

@media(min-width:768px)
{
    .elAsideTopSty{border-radius:10px 10px 0 0;border-top:3px solid #f39c12;display:block;}
    .platform_container{
        margin:0;padding:0;
        text-align:left;
        /deep/ .pfHeadTit{
            display:block;
            font-size:16px;
            margin-left:30px;padding:6px 15px;border-radius:50px;
        }
        /deep/ .asideMenuTrigger{
            display:none;
        }
        /deep/ .pfHeadTit:hover{
            // color:#0b9ef3;
            background:rgb(52, 58, 64);
            box-shadow:1px 1px 3px #222;
            color:#FFF;
        }
    }
    .el-container{
        width:1280px;
        background:#FFF;
        border-radius:unset;
        padding-left:unset;padding-right:unset;margin-bottom:unset;
    }
    .el-aside{overflow-x:hidden;overflow-y:hidden;}

    /deep/ .htmlSty{
        font-size:16px;
        line-height:30px;
    }
    .el-main{padding:20px;}
}
</style>
