<template>
	<div class="index_container">
		<main-header>
			<template slot="rightcontent">
				<div class="contactBox">
					<span class="headTit">商务合作</span>
					<img src="@/assets/imgs/icon_logo_1.png" alt="QRCODE" class="qrcode" />
				</div>
			</template>
		</main-header>
		
		<div class="main_content_box">
			<fast-enter></fast-enter>

			<div class="routeBlock">
				<div class="blockItem"
					v-for="item in indexMenu" :key="item.name"
					@click="gotoDocPage(item)">
					<span class="headTit cnTit">
						<img :src="getImgUrl(item)" :alt="item.label" class="iconRouteBlock" />
						{{ item.label }}
					</span>
					<span class="headTitEn enTit">
						<img :src="getImgUrl(item)" :alt="item.label" class="iconRouteBlock" />
						{{ item.labelEn }}
					</span>
				</div>
			</div>

			<div class="botInfoBox">
				<div class="infoTit">{{ infoTit }}</div>
				<div class="infoDesc">{{ infoDesc }}</div>
				<div class="infoView">
					<!-- Bottom Menu ON -->
					<!-- <div 
						v-for="item in botMenu" :key="item.label" 
						@click="gotoDocPage(item)">
						{{ item.children ? item.children[0].label : item.label }}
					</div> -->
					<!-- Bottom Menu OFF -->
					<div>
						Copyright © {{ currentYear }} MESH All rights reserved.
					</div>
				</div>
			</div>

			<div class="sLine"></div>
		</div>
	</div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import menuData   from '@/utils/menu.config.js';

export default {
	name: 'IndexCom',
	components: {
		MainHeader
	},

	data(){
		return {
			infoTit : '',
			infoDesc: '',
		}
	},

	created(){

    },

	methods: {
		getImgUrl(item)
		{
			return require("@/assets/imgs/" + item.icon);
		},

		// 跳转二级页面
		gotoDocPage(item)
		{
			if(item.children)
			{
				this.$router.push(item.children[0].path);
			}
			else
			{
				this.$router.push(item.path);
			}
		}
	},

	computed: {
		// 首页菜单
		indexMenu()
		{
			let res = menuData.filter(item => {
				return item.indexShow === true;
			});
			return res;
		},

		// 底部菜单
		botMenu()
		{
			let res = menuData.filter(item => item.botShow);
            return res;
		},

		currentYear()
		{
			var d = new Date();
			return d.getFullYear();
		}
	}
}
</script>

<style lang="less" scoped>
.index_container{
	margin:0;padding:0;
	background:url(@/assets/imgs/index_bg.png) top center no-repeat;background-size:cover;
	width:100%;height:100%;
}
.main_content_box{
	width:7rem;
	height:22rem;
	margin:0 auto;
	position:relative;
}
.routeBlock{
	width:7rem;margin:0 auto;
	position:absolute;top:.5rem;
	display:flex;flex-direction:column;justify-content:flex-start;align-items:center;
	z-index:20;
	.blockItem{
		width:100%;height:2.4rem;
		font-size:.52rem;color:#FFF;font-weight:500;
		background:rgba(0, 0, 0, 0.30);border-radius:.4rem;
		display:flex;justify-content:center;align-items:center;
		margin-right:0;margin-bottom:.25rem;
		position:relative;
		transition:all 0.6s;
		overflow:hidden;
		cursor:pointer;
		span{
			position:absolute;right:0;margin:auto;
			display:flex;justify-content:center;align-items:center;text-align:center;
			white-space:nowrap;
			transition:all 0.6s;
		}
		.cnTit{left:0;}
		.enTit{left:-13rem;}
		&:last-child{margin-right:0;}
		/* &:hover{
			width:400px;
			.cnTit{left:600px;}
			.enTit{left:0;}
		} */
		.iconRouteBlock{
			width:.88rem;height:.88rem;margin-right:.4rem;
		}
	}
}
.botInfoBox{
	width:7rem;margin:0 auto;margin-top:unset;
	position:absolute;top:18rem;
	display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;
	.infoTit{font-size:.56rem;color:#FFF;line-height:1rem;}
	.infoDesc{font-size:.36rem;color:#FFF;line-height:1.8;font-weight:normal;text-align:justify;}
	.infoView{
		width:100%;
		display:flex;justify-content:center;align-items:center;
		margin-top:30px;
		div{
			font-family:Consolas;font-size:.24rem;color:#FFF;zoom:.9;
			white-space:nowrap;
			padding:6px 20px;margin-right:0px;
			background:rgba(0, 0, 0, 0.20);border-radius:.36rem;
			cursor:pointer;
		}
	}
}
.sLine{margin:0 auto;width:100%;height:100px;}

@media(min-width:768px)
{
	.main_content_box{
		width:1280px;
		height:100vh;
		background:url(@/assets/imgs/icon_ad_1.png) right 60px no-repeat;
	}
	.routeBlock{
		width:1280px;margin-top:100px;
		position:unset;top:unset;
		flex-direction:row;justify-content:space-between;align-items:flex-start;
		.blockItem{
			width:300px;height:120px;
			font-size:26px;
			border-radius:20px;
			margin-right:20px;margin-bottom:0;
			.enTit{left:-600px;}
			&:hover{
				width:400px;
				.cnTit{left:600px;}
				.enTit{left:0;}
			}
			.iconRouteBlock{
				width:44px;height:44px;margin-right:20px;
			}
		}
	}
	.botInfoBox{
		width:1280px;margin-top:60px;top:unset;
		.infoTit{font-size:32px;line-height:50px;}
		.infoDesc{font-size:28px;line-height:50px;text-align:left;}
		.infoView{
			width:100%;
			margin-top:30px;
			div{
				font-size:12px;
				padding:6px 20px;
				border-radius:18px;
			}
		}
	}
}
</style>
