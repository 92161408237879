const menuData = [
    {
        icon     : 'icon_computer.png',
        label    : '小确幸',
        labelEn  : 'XQX',
        indexShow: true,
        topShow  : true,
        botShow  : false,
        children : [
            {
                label  : '记录每天发生的小确幸',
                path   : '/platform',
                name   : 'platform'
            }
        ]
    },
    {
        icon     : 'icon_direction.png',
        label    : '睡前故事',
        labelEn  : 'SQGS',
        indexShow: true,
        topShow  : true,
        botShow  : false,
        children : [
            {
                label  : 'story-01',
                path   : '/story',
                name   : 'story'
            }
        ]
    },
    {
        icon     : 'icon_phone_big.png',
        label    : '树洞解忧',
        labelEn  : 'SDJY',
        indexShow: true,
        topShow  : true,
        botShow  : false,
        children : [
            {
                label  : '倾听',
                path   : '/listen-to',
                name   : 'listen-to'
            }
        ]
    }
];

export default menuData;