(function(designWidth, maxWidth){
	var doc = document,
		win = window;
	var docEl = doc.documentElement;
	var metaEl,
		metaElCon;
	var styleText,
		remStyle = document.createElement("style");
	var tid;

	function refreshRem(){
		var width = docEl.getBoundingClientRect().width;
		if(!maxWidth){
			maxWidth = 540;
		};
		if(width > maxWidth){
			width = maxWidth;
		}
		var rem = width * 100 / designWidth;
		remStyle.innerHTML = 'html{font-size:' + rem + 'px;}';
	}

	metaEl    = doc.querySelector('meta[name="viewport"]');
	metaElCon = "width=device-width,initial-scale=1,maximum-scale=1.0,user-scalable=no,viewport-fit=cover";
	if(metaEl){
		metaEl.setAttribute("content", metaElCon);
	}else{
		metaEl = doc.createElement("meta");
		metaEl.setAttribute("name", "viewport");
		metaEl.setAttribute("content", metaElCon);
		if(docEl.firstElementChild){
			docEl.firstElementChild.appendChild(metaEl);
		}else{
			var wrap = doc.createElement("div");
			wrap.appendChild(metaEl);
			doc.write(wrap.innerHTML);
			wrap = null;
		}
	}

	refreshRem();

	if(docEl.firstElementChild){
		docEl.firstElementChild.appendChild(remStyle);
	}else{
		var wrap = doc.createElement("div");
		wrap.appendChild(remStyle);
		doc.write(wrap.innerHTML);
		wrap = null;
	}

	win.addEventListener("resize", function(){
		clearTimeout(tid);
		tid = setTimeout(refreshRem, 300);
	}, false);

	win.addEventListener("pageshow", function(e){
		if(e.persisted){
			clearTimeout(tid);
			tid = setTimeout(refreshRem, 300);
		}
	}, false);

	if(doc.readyState === "complete"){
		doc.body.style.fontSize = "0.16rem";
	}else{
		doc.addEventListener("DOMContentLoaded", function(e){
			doc.body.style.fontSize = "0.16rem";
		}, false);
	}
})(750, 1200);
