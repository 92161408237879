<template>
    <div class="main_header_container">
        <div class="headerBox">
            <div class="headerArea">
                <div class="l_con">
                    <img src="../assets/imgs/icon_logo_1.png" alt="LOGO" class="logo" @click="gotoIndex">
                    <span class="headTit">{{ logoTitle }}</span>
                </div>

                <div class="r_con">
                    <slot name="rightcontent"></slot>
                    <slot name="rightMenuTrigger"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name      : 'MainHeaderCom',
    components: {
    },
    
    data(){
        return {
            logoTitle: ''
        }
    },
    
    created(){
        
    },
    
    methods: {
        gotoIndex()
        {
            // 当前页面的路由与跳转的路由不一致时，才允许跳转
            let _index = '/';
			if(this.$route.path != _index)
			{
				this.$router.push(_index);
			}
        }
    },
    
    computed: {
    }
}
</script>

<style lang="less" scoped>
.main_header_container{margin:0;padding:0;}
.headerBox{
    width:100%;height:2rem;background:rgba(255, 255, 255, 0.20);
    .headerArea{
        position:relative;
        display:flex;justify-content:center;align-items:center;
        margin:0 auto;width:100%;
        .l_con{
            display:flex;justify-content:flex-start;align-items:center;
            height:2rem;
            /* background:url(../assets/imgs/bulma.png) no-repeat;
            background-size:50% 50%; */
            .logo{width:4.4rem;height:1.2rem;cursor:pointer;}
            .headTit{
                display:none;
                font-size:.36rem;color:#333;font-weight:500;
                margin-left:0;
            }
        }
        .r_con{
            display:flex;justify-content:center;align-items:center;
            width:1rem;height:2rem;
            position:absolute;right:0;
            .contactBox{display:none;}
        }
    }
}

@media(min-width:768px)
{
    .headerBox{
        height:100px;
        .headerArea{
            justify-content:space-between;
            width:1280px;
            .l_con{
                height:100px;
                .logo{width:220px;height:60px;}
                .headTit{
                    display:block;
                    font-size:24px;
                }
            }
            .r_con{
                display:flex;justify-content:flex-end;align-items:center;
                width:auto;height:100px;
                position:static;right:unset;
                .headTit{font-size:24px;color:#222;font-weight:500;margin-right:16px;}
                .qrcode{width:80px;height:80px;border-radius:5px;}
                .contactBox{display:flex;justify-content:flex-end;align-items:center;}
            }
        }
    }
}
</style>
