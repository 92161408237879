<template>
    <div class="main_footer_container">
        <div class="footerBox">
            <div class="footerArea">
                <div class="l_con">
                    <span class="copyrightInfo">
                        Copyright © {{ currentYear }} JIEYOU.SHOP All rights reserved.
                        <a target="_blank" href="http://beian.miit.gov.cn">京ICP备19037879号-1</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name      : 'MainFooterCom',
    components: {
    },
    
    data(){
        return {
            
        }
    },
    
    created(){
        
    },
    
    methods: {
        
    },
    
    computed: {
        // This Year
		currentYear()
		{
			var d = new Date();
			return d.getFullYear();
		}
    }
}
</script>

<style lang="less" scoped>
.main_footer_container{margin:0;padding:0;}
.footerBox{
    width:100%;height:2rem;background:rgba(255, 255, 255, 0.20);
    .footerArea{
        position:relative;
        display:flex;justify-content:center;align-items:center;
        margin:0 auto;width:100%;
        .l_con{
            display:flex;justify-content:flex-start;align-items:center;
            height:2rem;
            .copyrightInfo{
                display:none;
                font-size:.36rem;color:#333;font-weight:500;
                margin-left:0;
            }
        }
    }
}

@media(min-width:768px)
{
    .footerBox{
        height:100px;
        .footerArea{
            justify-content:space-between;
            .l_con{
                height:100px;
                .copyrightInfo{
                    display:block;
                    font-size:12px;
                }
            }
        }
    }
}
</style>
