import Vue       from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App       from './App.vue';
import router    from './router';
import VueMeta   from 'vue-meta';
import './utils/flexible_750.js';

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(VueMeta, {
    // 定义组件中的数据对象
    keyName: 'head'
});

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
