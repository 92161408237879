<template>
    <div class="story_container">
        <p class="htmlSty" v-html="showHtml"></p>
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue';
import conData    from '@/files/story01';

export default {
    name      : 'StoryCom',
    components: {
        MainFooter
    },
    
    data(){
        return {
            showHtml: conData.content
        }
    },
    
    created(){
        
    },
    
    methods: {
        foo()
        {
            
        }
    }
}
</script>

<style lang="less" scoped>
.story_container{margin:0;padding:0;}
/deep/ .zzz{font-size:12px;color:#666;}
.htmlSty{height:700px;overflow-y:scroll;}
</style>
