import Vue             from 'vue';
import VueRouter       from 'vue-router';
import Index           from '@/views/Index/Index.vue';
import Documents       from '@/views/Documents/Documents.vue';
import Platform        from '@/views/Documents/Platform.vue';
import Story           from '@/views/Documents/Story.vue';
import ListenTo        from '@/views/Documents/ListenTo.vue';

Vue.use(VueRouter);

const routes = [
	// 首页路由规则
	{
		path     : '/',
		component: Index,
		redirect : '/platform',// 强制跳转到二级页
		meta     : {
			// 页面标题title
			title: '首页'
		}
	},
	// 二级页路由规则
	{
		path     : '/platform',
		component: Documents,
		redirect : '/platform',
		// 子路由
		children: [
			{
				// 二级页路由规则：小确幸
				path     : '/platform',
				component: Platform
			},
			{
				// 二级页路由规则：睡前故事
				path     : '/story',
				component: Story
			},
			{
				// 二级页路由规则：树洞解忧
				path     : '/listen-to',
				component: ListenTo
			}
		]
	}
];

const router = new VueRouter({
	routes
});

export default router;
