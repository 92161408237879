<template>
	<div id="app" class="app_container">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'AppCom'
}
</script>

<style lang="less">
*{
	box-sizing:border-box;font-family:MicrosoftYaHei;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}
html, 
body, 
.app_container{
	margin:0;padding:0;
	overflow-x:hidden;overflow-y:auto;
}
img{border:none;outline:none;}
.zdyElMsg{
    min-width:100% !important;max-width:100% !important;
    border-radius:0 !important;
    top:0 !important;
	.el-message__icon,
	.el-message__content{
        font-size:.32rem;
    }
}
.zdyElAlert{
	width:100%;
	border:unset !important;
	border-radius:0 !important;
	box-shadow:unset !important;
    position:absolute;top:0;left:0;right:0;margin:auto;
	padding-bottom:unset !important;
	.el-message-box__header{
		padding:.3rem;
	}
	.el-message-box__title{
		font-size:.36rem;
		span{
			&::after{
				content: "\e6c9";
				font-family:element-icons!important;
				font-weight:bold;color:#F60;
			}
		}
	}
	.el-message-box__content{
		padding:.2rem .3rem .4rem .3rem;
	}
	.el-message-box__message{
		font-size:.28rem;
		p{
			text-align:justify;
			line-height:.48rem;
		}
	}
	.el-message-box__btns{
		display:none;
	}
}
.el-popup-parent--hidden{padding-right:0 !important;}

@media(min-width:768px)
{
	html,
	body,
	.app_container{
		margin:0;padding:0;
		overflow-x:hidden;overflow-y:hidden;
	}
	.zdyElMsg{
		min-width:380px !important;max-width:380px !important;
		border-radius:10px !important;
		top:20px !important;
		.el-message__icon,
		.el-message__content{
			font-size:14px;
		}
	}

	.zdyElAlert{
		width:350px;
		border-radius:10px !important;
		box-shadow:unset !important;
		position:absolute;top:30%;left:0;right:0;margin:auto;
		padding-bottom:unset !important;
		.el-message-box__header{
			padding:20px;
		}
		.el-message-box__title{
			font-size:18px;
		}
		.el-message-box__content{
			padding:0 20px 20px 20px;
		}
		.el-message-box__message{
			font-size:14px;
			p{
				text-align:justify;
				line-height:1.8;
			}
		}
		.el-message-box__btns{
			display:none;
		}
	}
}
</style>
